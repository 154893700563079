import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {AccountCircle as AccountCircleIcon} from '@material-ui/icons'
import * as React from 'react'
import RouteLink from '../../components/RouteLink'

export interface AuthenticatedLayoutProps {
  onLogout?: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    textAlign: 'left',
  },
}))

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  onLogout,
  children,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const popoverOpen = Boolean(anchorEl)

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    if (onLogout) {
      onLogout()
    }

    handlePopoverClose()
  }

  return (
    <Box className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <RouteLink to="/admin">
            <Typography variant="h6" noWrap align="left">
              Form adminisztráció
            </Typography>
          </RouteLink>

          <Box>
            <IconButton
              edge="end"
              aria-label="Felhasználói műveletek"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handlePopoverOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={popoverOpen}
              onClose={handlePopoverClose}
            >
              <MenuItem onClick={handleLogout}>Kijelentkezés</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Container component="main" className={classes.content}>
        <div className={classes.contentHeader} />

        {children}
      </Container>
    </Box>
  )
}
