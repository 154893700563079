import * as React from 'react'
import {ConfirmationContext} from '../contexts'

export function useConfirmation() {
  const context = React.useContext(ConfirmationContext)

  if (!context) {
    throw new Error(
      `'useConfirmation' should be used within a ConfirmationProvider.`,
    )
  }

  return context
}

export default useConfirmation
