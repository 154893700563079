type EmailData = {
  to: string
  subject: string
  message: string
  authenticity?: string
}

export function useEmailService() {
  async function sendEmail({to, subject, message, authenticity}: EmailData) {
    const response = await window.fetch(
      `https://us-central1-rudnaykrisztina-12663.cloudfunctions.net/email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to,
          subject,
          message,
          authenticity,
        }),
      },
    )

    const data = await response.json()

    if (response.status >= 300) {
      throw new Error(data)
    }

    return data
  }

  return {sendEmail}
}
