import {CircularProgress, Typography} from '@material-ui/core'
import * as React from 'react'
import {Redirect, useRouteMatch} from 'react-router'
import {firebaseApp} from '../App'
import Questionnaire from '../components/Questionnaire'
import useAuthentication from '../hooks/useAuthentication'
import useSignOut from '../hooks/useSignOut'
import {AuthenticatedLayout} from '../layout'
import {errorMessages} from '../messages/errors'
import {DataState} from '../types/common'

export interface ResponsePageProps {}

const ResponsePageContent: React.FC = () => {
  const {params} = useRouteMatch<{id: string}>()
  const {id} = params
  const {user, authState} = useAuthentication()
  const signOut = useSignOut()
  // todo: replace any with actual type created for our form
  const [entry, setEntry] = React.useState<any>()
  const [dataState, setDataState] = React.useState<DataState>('idle')

  const fetchResults = React.useCallback(
    async function fetchResults() {
      setDataState('pending')

      if (user && !user.isAnonymous) {
        try {
          const data = await firebaseApp
            .database()
            .ref(`results/${id}`)
            .once('value')
          const value = data.val()

          setEntry({...value, id})
          setDataState('resolved')
        } catch {
          setDataState('rejected')
        }
      } else if (user && user.isAnonymous) {
        await signOut()
      }
    },
    [user, id, signOut],
  )

  React.useEffect(() => {
    fetchResults()
  }, [fetchResults])

  if (authState === 'rejected') {
    return <Redirect to="/admin" />
  }

  if (dataState === 'idle') {
    return null
  }

  if (dataState === 'pending') {
    return <CircularProgress />
  }

  if (dataState === 'rejected') {
    return <Typography color="error">{errorMessages.unknownError}</Typography>
  }

  return <Questionnaire initialValues={entry} readOnly />
}

export const ResponsePage: React.FC<ResponsePageProps> = () => {
  const signOut = useSignOut()

  return (
    <AuthenticatedLayout onLogout={signOut}>
      <ResponsePageContent />
    </AuthenticatedLayout>
  )
}

export default ResponsePage
