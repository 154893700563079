import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core'
import {FieldAttributes, useField} from 'formik'
import React from 'react'
import Checkbox, {CheckboxProps} from './Checkbox'

export type CheckboxGroupProps = {
  questionLabel: string
  checkboxProps: Array<Omit<CheckboxProps, 'name'>>
  readOnly?: boolean
} & FieldAttributes<{}>

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  questionLabel,
  checkboxProps,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(props)

  const errorText = meta.error && meta.touched ? meta.error : ''
  const checkboxInputs = checkboxProps
    .filter(element =>
      readOnly
        ? Array.isArray(field.value)
          ? field.value.indexOf(element.value) > -1
          : false
        : true,
    )
    .map(element => (
      <Checkbox
        value={element.value}
        name={field.name}
        label={element.label}
        disabled={readOnly}
        key={element.label}
      />
    ))

  return (
    <FormControl error={!!errorText}>
      <FormLabel>{questionLabel}</FormLabel>
      <FormGroup>
        {checkboxInputs && checkboxInputs.length ? (
          checkboxInputs
        ) : (
          <Typography color="textSecondary" variant="subtitle2">
            Nem érhető el egy válasz sem.
          </Typography>
        )}
      </FormGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

export default CheckboxGroup
