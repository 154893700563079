import {Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Form} from 'formik'
import * as React from 'react'
import Field from './Field'

export interface LoginFormProps {
  errorMessage?: string
  isSubmitting?: boolean
}

const useStyles = makeStyles(theme => ({
  main: {
    paddingTop: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export const LoginForm: React.FC<LoginFormProps> = ({
  errorMessage,
  isSubmitting,
}) => {
  const classes = useStyles()

  return (
    <main className={classes.main}>
      <Form className={classes.form}>
        <Field
          id="email-field"
          name="email"
          placeholder="Email cím"
          type="email"
          variant="outlined"
          shrink
          required
        />

        <Field
          id="password-field"
          name="password"
          placeholder="Jelszó"
          type="password"
          variant="outlined"
          shrink
          required
        />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          Bejelentkezés
        </Button>

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Form>
    </main>
  )
}
