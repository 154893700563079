import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './App.css'
import {AdminPage, IndexPage, ResponsePage} from './pages'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCcE07pvtRM4KDHqZJYch34u4zCyEykPNA',
  authDomain: 'rudnaykrisztina-12663.firebaseapp.com',
  databaseURL: 'https://rudnaykrisztina-12663-default-rtdb.firebaseio.com',
  projectId: 'rudnaykrisztina-12663',
  storageBucket: 'rudnaykrisztina-12663.appspot.com',
  messagingSenderId: '485913032267',
  appId: '1:485913032267:web:526033e5d5b1c89472bfce',
})

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={IndexPage} />
          <Route path="/admin" exact component={AdminPage} />
          <Route path="/response/:id" exact component={ResponsePage} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
