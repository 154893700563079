import {Typography} from '@material-ui/core'
import * as React from 'react'
import {NotificationDialog} from '../components/NotificationDialog'

export interface Notification {
  text: string
  type: 'error' | 'success'
}

export const NotificationContext = React.createContext<
  | [
      Notification | undefined,
      React.Dispatch<React.SetStateAction<Notification | undefined>>,
    ]
  | undefined
>(undefined)

export const NotificationProvider: React.FC = ({children}) => {
  const notificationState = React.useState<Notification>()
  const [notification, setNotification] = notificationState

  function handleClose() {
    setNotification(undefined)
  }

  return (
    <NotificationContext.Provider value={notificationState}>
      {notification && (
        <NotificationDialog type={notification.type} onClose={handleClose}>
          <Typography align="left">{notification.text}</Typography>
        </NotificationDialog>
      )}

      {children}
    </NotificationContext.Provider>
  )
}
