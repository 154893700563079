import {TextField, withStyles} from '@material-ui/core'
import {FieldAttributes, useField} from 'formik'
import React from 'react'

//This component allow you to make input fields. (String and number fields as well)

export type FieldProps = {
  id?: string
  label?: string
  variant?: 'standard' | 'outlined' | 'filled'
  placeholder?: string
  required?: boolean
  shrink?: boolean
} & FieldAttributes<{}>

const StyledTextField = withStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    width: '25ch',
  },
}))(TextField)

export const Field: React.FC<FieldProps> = ({
  id,
  label,
  variant,
  placeholder,
  required,
  shrink,
  type,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ''

  return (
    <StyledTextField
      {...field}
      type={type}
      id={id}
      label={label}
      variant={variant}
      required={required}
      placeholder={placeholder}
      helperText={errorText}
      error={!!errorText}
      disabled={disabled}
      InputLabelProps={{
        shrink: shrink,
      }}
    />
  )
}

export default Field
