import {Button, Container, Typography, withStyles} from '@material-ui/core'
import {Replay as ReplayIcon} from '@material-ui/icons'
import * as React from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
  FallbackProps,
} from 'react-error-boundary'

export type ErrorBoundaryProps = Omit<ReactErrorBoundaryProps, 'fallbackRender'>

const StyledContainer = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))(Container)

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <StyledContainer>
      <Typography color="error">{error.message}</Typography>

      <Button
        onClick={resetErrorBoundary}
        variant="contained"
        color="primary"
        endIcon={<ReplayIcon />}
      >
        Try again
      </Button>
    </StyledContainer>
  )
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  ...props
}) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} {...props}>
      {children}
    </ReactErrorBoundary>
  )
}
