import {Checkbox as MaterialCheckbox, FormControlLabel} from '@material-ui/core'
import {FieldAttributes, useField} from 'formik'
import React from 'react'

export type CheckboxProps = {
  label: string
  name: string
  value: any
} & FieldAttributes<any>

export const Checkbox: React.FC<CheckboxProps> = props => {
  const [field] = useField(props)

  return (
    <FormControlLabel
      {...field}
      checked={(field.value ?? []).indexOf(props.value) > -1}
      control={<MaterialCheckbox />}
      {...props}
    />
  )
}

export default Checkbox
