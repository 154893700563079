import {FormControlLabel, Radio as MaterialRadio} from '@material-ui/core'
import React from 'react'

export interface RadioProps {
  label: string
  value: any
  disabled?: boolean
}

export const Radio: React.FC<RadioProps> = props => {
  return <FormControlLabel control={<MaterialRadio />} {...props} />
}

export default Radio
