import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup as MaterialRadioGroup,
  Typography,
} from '@material-ui/core'
import {FieldAttributes, useField} from 'formik'
import React from 'react'
import Radio, {RadioProps} from './Radio'

type Props = {
  questionLabel: string
  radioProps: RadioProps[]
  readOnly?: boolean
} & FieldAttributes<{}>

const RadioGroup: React.FC<Props> = ({
  questionLabel,
  radioProps,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(props)

  const errorText = meta.error && meta.touched ? meta.error : ''
  const radioInputs = radioProps
    .filter(element => (readOnly ? element.value === field.value : true))
    .map(element => (
      <Radio
        value={element.value}
        label={element.label}
        disabled={readOnly}
        key={element.value}
      />
    ))

  return (
    <FormControl error={!!errorText}>
      <FormLabel>{questionLabel}</FormLabel>
      <MaterialRadioGroup {...field}>
        {radioInputs && radioInputs.length ? (
          radioInputs
        ) : (
          <Typography color="textSecondary" variant="subtitle2">
            Nem érhető el egy válasz sem.
          </Typography>
        )}
      </MaterialRadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

export default RadioGroup
