export interface InputGroup {
  question: string
  name: string
  type: 'radio' | 'checkbox'
  inputFields: Array<{value: string; label: string}>
}

export const inputGroups: Array<InputGroup> = [
  {
    question: 'Mi az ön neme?',
    name: 'gender',
    type: 'radio',
    inputFields: [
      {
        value: 'férfi',
        label: 'férfi',
      },
      {
        value: 'nő',
        label: 'nő',
      },
    ],
  },
  {
    question: 'Miért nem érzed magad egyensúlyban?',
    name: 'healthTarget',
    type: 'radio',
    inputFields: [
      {
        value: 'Fogyni szeretnék 1-5 kilót',
        label: 'Fogyni szeretnék 1-5 kilót',
      },
      {
        value: 'Fogyni szeretnék 5-10 kilót',
        label: 'Fogyni szeretnék 5-10 kilót',
      },
      {
        value: 'Fogyni szeretnék 10+ kilót',
        label: 'Fogyni szeretnék 10+ kilót',
      },
      {
        value: 'Szeretem a testsúlyom, csak egészségesen étkeznék',
        label: 'Szeretem a testsúlyom, csak egészségesen étkeznék',
      },
      {
        value: 'Szeretem a testsúlyom, csak egészségügyi problémáim vannak',
        label: 'Szeretem a testsúlyom, csak egészségügyi problémáim vannak',
      },
      {
        value: 'Hizni szeretnék pár kilót',
        label: 'Hizni szeretnék pár kilót',
      },
    ],
  },
  {
    question: 'Edzel jelenleg?',
    name: 'trainingCount',
    type: 'radio',
    inputFields: [
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: 'Heti 1-2 napot, nem túl intenziven',
        label: 'Heti 1-2 napot, nem túl intenziven',
      },
      {
        value: 'Heti 3-5 napot, nem túl intenziven',
        label: 'Heti 3-5 napot, nem túl intenziven',
      },
      {
        value: 'Heti 3-5 napot, intenziven',
        label: 'Heti 3-5 napot, intenziven',
      },
      {
        value: 'Heti 5-7 napot, intenziven',
        label: 'Heti 5-7 napot, intenziven',
      },
    ],
  },
  {
    question: 'Szenvedsz-e valamelyiktől?',
    name: 'sufferFromDisease',
    type: 'checkbox',
    inputFields: [
      {
        value: 'Puffadás',
        label: 'Puffadás',
      },
      {
        value: 'Hangulatváltozások',
        label: 'Hangulatváltozások',
      },
      {
        value: 'Gyulladás (belső)',
        label: 'Gyulladás (belső)',
      },
      {
        value: 'Hasfájás',
        label: 'Hasfájás',
      },
      {
        value: 'Székrekedés',
        label: 'Székrekedés',
      },
      {
        value: 'Hasmenés',
        label: 'Hasmenés',
      },
      {
        value: 'Hasimoto',
        label: 'Hasimoto',
      },
      {
        value: 'IBS (irritativ bélszindróma)',
        label: 'IBS (irritativ bélszindróma)',
      },
      {
        value: 'Béláteresztő szindróma',
        label: 'Béláteresztő szindróma',
      },
      {
        value: 'Étel intolerancia',
        label: 'Étel intolerancia',
      },
      {
        value: 'Gyakori / visszatarthatatlan vizelés',
        label: 'Gyakori / visszatarthatatlan vizelés',
      },
      {
        value: 'IR (inzulin ellenállás)',
        label: 'IR (inzulin ellenállás)',
      },
      {
        value: 'Diabétesz',
        label: 'Diabétesz',
      },
      {
        value: 'Hisztamin allergia',
        label: 'Hisztamin allergia',
      },
      {
        value: 'Magas vérnyomás',
        label: 'Magas vérnyomás',
      },
      {
        value: 'Magas vércukor',
        label: 'Magas vércukor',
      },
      {
        value: 'Pajzsmirigy alulműködés',
        label: 'Pajzsmirigy alulműködés',
      },
      {
        value: 'Pajzsmirigy túlműködés',
        label: 'Pajzsmirigy túlműködés',
      },
      {
        value: 'Hormonális zavarok',
        label: 'Hormonális zavarok',
      },
      {
        value: 'Álmatlanság/rossz alvás',
        label: 'Álmatlanság/rossz alvás',
      },
      {
        value: 'Cukor/szénhidrát függés',
        label: 'Cukor/szénhidrát függés',
      },
      {
        value: 'Lassú anyagcsere',
        label: 'Lassú anyagcsere',
      },
      {
        value: 'Felszivódási problémák',
        label: 'Felszivódási problémák',
      },
      {
        value: 'Emésztési problémák',
        label: 'Emésztési problémák',
      },
      {
        value: 'Gázok',
        label: 'Gázok',
      },
      {
        value: 'Reflux',
        label: 'Reflux',
      },
      {
        value: 'Vitaminhiány (igazolt)',
        label: 'Vitaminhiány (igazolt)',
      },
      {
        value: 'Izületi fájdalmak',
        label: 'Izületi fájdalmak',
      },
      {
        value: 'Vizesedés',
        label: 'Vizesedés',
      },
      {
        value: 'Áteresztő bél szindróma',
        label: 'Áteresztő bél szindróma',
      },
      {
        value: 'Depresszió / mentális betegségek',
        label: 'Depresszió / mentális betegségek',
      },
      {
        value: 'Egyik sem',
        label: 'Egyik sem',
      },
    ],
  },
  {
    question: 'Milyen a jelenlegi diétád?',
    name: 'currentDiet',
    type: 'checkbox',
    inputFields: [
      {
        value: 'Csak eszem amit megkivánok (vegán)',
        label: 'Csak eszem amit megkivánok (vegán)',
      },
      {
        value: 'Csak eszem amit megkivánok (vegyes)',
        label: 'Csak eszem amit megkivánok (vegyes)',
      },
      {
        value: 'Csak eszem amit megkivánok (vegetáriánus)',
        label: 'Csak eszem amit megkivánok (vegetáriánus)',
      },
      {
        value: 'Teljes értékű növényi alapú TÉNÉ (amit megkivánok)',
        label: 'Teljes értékű növényi alapú TÉNÉ (amit megkivánok)',
      },
      {
        value: 'Teljes értékű, növényi alapú TÉNÉ (alacsony szénhidrát)',
        label: 'Teljes értékű, növényi alapú TÉNÉ (alacsony szénhidrát)',
      },
      {
        value: 'A napi tucat szerint étkezem',
        label: 'A napi tucat szerint étkezem',
      },
      {
        value: 'Alacsony szénhidrát (vegán)',
        label: 'Alacsony szénhidrát (vegán)',
      },
      {
        value: 'Alacsony szénhidrát (vegyes)',
        label: 'Alacsony szénhidrát (vegyes)',
      },
      {
        value: 'Alacsony zsir (vegán)',
        label: 'Alacsony zsir (vegán)',
      },
      {
        value: 'Alacsony zsir (vegyes)',
        label: 'Alacsony zsir (vegyes)',
      },
      {
        value: 'Paleo',
        label: 'Paleo',
      },
      {
        value: 'Keto',
        label: 'Keto',
      },
      {
        value: 'Vegán keto',
        label: 'Vegán keto',
      },
      {
        value: 'IF (időszakos böjt) - vegán',
        label: 'IF (időszakos böjt) - vegán',
      },
      {
        value: 'IF (időszakos böjt) - vegyes',
        label: 'IF (időszakos böjt) - vegyes',
      },
      {
        value: 'Gluténmentes',
        label: 'Gluténmentes',
      },
      {
        value: 'Még csak gondolkozom rajta, tanácstalan vagyok, hol kezdjem el',
        label: 'Még csak gondolkozom rajta, tanácstalan vagyok, hol kezdjem el',
      },
      {
        value: 'Blue zone diéta',
        label: 'Blue zone diéta',
      },
      {
        value: 'Más',
        label: 'Más',
      },
    ],
  },
  {
    question: 'Növényi étrendet követsz?',
    name: 'plantDiet',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: '70-90%-ban igen',
        label: '70-90%-ban igen',
      },
      {
        value: 'Most kezdem',
        label: 'Most kezdem',
      },
      {
        value: 'Csak gondolkozom rajta',
        label: 'Csak gondolkozom rajta',
      },
    ],
  },
  {
    question: 'Számolod a makrókat?',
    name: 'countMacros',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: 'Néha',
        label: 'Néha',
      },
      {
        value: 'Nem tudom, hogy kell',
        label: 'Nem tudom, miért lenne fontos',
      },
    ],
  },
  {
    question: 'Írsz étkezési naplót?',
    name: 'hasMealDiary',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: 'Néha',
        label: 'Néha',
      },
      {
        value: 'Nem tudom, hogy kell',
        label: 'Nem tudom, hogy kell',
      },
      {
        value: 'Nem tudom, miért lenne fontos',
        label: 'Nem tudom, miért lenne fontos',
      },
    ],
  },
  {
    question:
      'Írod a hangulatodat, érzéseidet, tested reakcióit amellett, hogy mit ettél?',
    name: 'isNoteFeelings',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: 'Néha',
        label: 'Néha',
      },
      {
        value: 'Nem tudom, hogy kell',
        label: 'Nem tudom, hogy kell',
      },
      {
        value: 'Nem tudom, miért lenne fontos',
        label: 'Nem tudom, miért lenne fontos',
      },
    ],
  },
  {
    question: 'Hányszor fogyasztasz állati eredetű ételt (nem hús) egy héten?',
    name: 'animalOriginNotMeatCount',
    type: 'radio',
    inputFields: [
      {
        value: 'Annál ritkábban fogyasztok',
        label: 'Annál ritkábban fogyasztok',
      },
      {
        value: 'Naponta',
        label: 'Naponta',
      },
      {
        value: 'Hetente többször',
        label: 'Hetente többször',
      },
      {
        value: 'Egyszer-kétszer',
        label: 'Egyszer-kétszer',
      },
      {
        value: 'Egyszer',
        label: 'Egyszer',
      },
    ],
  },
  {
    question: 'Hányszor fogyasztasz állati eredetű ételt (hús) egy héten?',
    name: 'animalOriginMeatCount',
    type: 'radio',
    inputFields: [
      {
        value: 'Annál ritkábban fogyasztok',
        label: 'Annál ritkábban fogyasztok',
      },
      {
        value: 'Naponta',
        label: 'Naponta',
      },
      {
        value: 'Hetente többször',
        label: 'Hetente többször',
      },
      {
        value: 'Egyszer-kétszer',
        label: 'Egyszer-kétszer',
      },
      {
        value: 'Egyszer',
        label: 'Egyszer',
      },
    ],
  },
  {
    question: 'Hányszor fogyasztasz feldolgozott élelmiszereket egy héten?',
    name: 'consumedProcessedFoodCount',
    type: 'radio',
    inputFields: [
      {
        value: 'Annál ritkábban fogyasztok',
        label: 'Annál ritkábban fogyasztok',
      },
      {
        value: 'Naponta',
        label: 'Naponta',
      },
      {
        value: 'Hetente többször',
        label: 'Hetente többször',
      },
      {
        value: 'Egyszer-kétszer',
        label: 'Egyszer-kétszer',
      },
      {
        value: 'Egyszer',
        label: 'Egyszer',
      },
    ],
  },
  {
    question: 'Terhes vagy/szoptatsz?',
    name: 'isPregnant',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
    ],
  },
  {
    question: 'Az orvosod eltiltott diétától vagy testmozgástól?',
    name: 'isDisabledFromDiet',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
    ],
  },
  {
    question: 'Mikor volt az utolsó vérvizsgálatod?',
    name: 'lastBloodTest',
    type: 'radio',
    inputFields: [
      {
        value: 'Pár hónapja',
        label: 'Pár hónapja',
      },
      {
        value: 'Pár hete',
        label: 'Pár hete',
      },
      {
        value: 'Egy évnél nem régebben',
        label: 'Egy évnél nem régebben',
      },
      {
        value: 'Egy évnél régebben',
        label: 'Egy évnél régebben',
      },
      {
        value: '2 évnél régebben',
        label: '2 évnél régebben',
      },
      {
        value: 'Soha',
        label: 'Soha',
      },
    ],
  },
  {
    question:
      'Tagja vagy a Növényi alapú vega + vegán életmód - receptek és edzéstervek nevű FB csoportnak?',
    name: 'isMemberOfVeganFbGroup',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
      {
        value: 'Érdekelne',
        label: 'Érdekelne',
      },
      {
        value: 'Nem érdekel',
        label: 'Nem érdekel',
      },
    ],
  },
  {
    question: 'Van valami aggasztó az egészségedben?',
    name: 'isSomethingWorrying',
    type: 'radio',
    inputFields: [
      {
        value: 'Igen',
        label: 'Igen',
      },
      {
        value: 'Nem',
        label: 'Nem',
      },
    ],
  },
]

export const availableInputs = inputGroups.map(group => ({
  name: group.name,
  value: group.type === 'checkbox' ? ([] as Array<string>) : '',
  type: group.type,
}))
