import * as React from 'react'
import {firebaseApp} from '../App'
import {DataState} from '../types/common'

export function useAuthentication() {
  const [user, setUser] = React.useState<firebase.default.User | null>()
  const [authState, setAuthState] = React.useState<DataState>('idle')

  React.useEffect(() => {
    setAuthState('pending')

    const unsubscribeAuthStateListener = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          setUser(user)
          setAuthState('resolved')
        } else {
          setAuthState('rejected')
        }
      })

    return () => {
      unsubscribeAuthStateListener()
    }
  }, [setUser])

  return {user, authState}
}

export default useAuthentication
