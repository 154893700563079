import {Card, IconButton, makeStyles, withStyles} from '@material-ui/core'
import {Close as CloseIcon} from '@material-ui/icons'
import * as React from 'react'

export interface NotificationDialogProps {
  type: 'error' | 'success'
  onClose?: () => void
}

const StyledCard = withStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    top: theme.spacing(2),
    left: 0,
    right: 0,
    margin: '0 auto',
    maxWidth: 380,
    padding: theme.spacing(2),
    borderRadius: 12,
    zIndex: theme.zIndex.appBar + 1,
    lineHeight: '150%',
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  success: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.getContrastText(theme.palette.success.dark),
  },
}))

export const NotificationDialog: React.FC<NotificationDialogProps> = ({
  type,
  onClose,
  children,
}) => {
  const classes = useStyles()

  return (
    <StyledCard elevation={0} className={classes[type]}>
      {children}

      {onClose && (
        <IconButton
          aria-label="Értesítés bezárása"
          onClick={onClose}
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      )}
    </StyledCard>
  )
}
