import {makeStyles} from '@material-ui/core'
import clsx from 'clsx'
import * as React from 'react'
import {Link, LinkProps} from 'react-router-dom'

export interface RouteLinkProps extends LinkProps {}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const RouteLink: React.FC<RouteLinkProps> = ({className, ...props}) => {
  const classes = useStyles()

  return <Link className={clsx(className, classes.root)} {...props} />
}

export default RouteLink
