import {ThemeProvider} from '@material-ui/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import 'whatwg-fetch'
import App from './App'
import {ErrorBoundary} from './components/ErrorBoundary'
import {ConfirmationProvider, NotificationProvider} from './contexts'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {lightTheme} from './theme'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <ErrorBoundary>
        <NotificationProvider>
          <ConfirmationProvider>
            <App />
          </ConfirmationProvider>
        </NotificationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
