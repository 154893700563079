import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import * as React from 'react'

export interface Confirmation {
  title: string
  message: string
  onOkClick?: VoidFunction
  onCancelClick?: VoidFunction
}

export const ConfirmationContext = React.createContext<
  | [
      Confirmation | undefined,
      React.Dispatch<React.SetStateAction<Confirmation | undefined>>,
    ]
  | undefined
>(undefined)

/**
 * Dialog transition duration.
 */
const TRANSITION_DURATION = 250

export const ConfirmationProvider: React.FC = ({children}) => {
  const confirmationState = React.useState<Confirmation>()
  const [confirmation, setConfirmation] = confirmationState
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (confirmation) {
      setOpen(true)
    }
  }, [confirmation])

  function handleClose() {
    setOpen(false)

    // note: this is required to make sure content doesn't disappear during
    // fade animation
    setTimeout(() => {
      setConfirmation(undefined)
    }, TRANSITION_DURATION)
  }

  function handleOkClick() {
    if (confirmation && confirmation.onOkClick) {
      confirmation.onOkClick()
    }

    handleClose()
  }

  function handleCancelClick() {
    if (confirmation && confirmation.onCancelClick) {
      confirmation.onCancelClick()
    }

    handleClose()
  }

  return (
    <ConfirmationContext.Provider value={confirmationState}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={TRANSITION_DURATION}
        keepMounted
      >
        <DialogTitle id="alert-dialog-title">{confirmation?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmation?.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            Mégsem
          </Button>
          <Button onClick={handleOkClick} color="primary" autoFocus>
            Rendben
          </Button>
        </DialogActions>
      </Dialog>

      {children}
    </ConfirmationContext.Provider>
  )
}
