export class Evaluation {
  constructor(private data: any) {}

  private BmrCalculation = (
    weight: number,
    height: number,
    age: number,
    gender: number,
    multiplier: number,
  ): number => {
    return Math.floor(
      (9.99 * weight + 6.2 * height - 4.92 * age + gender) * multiplier,
    )
  }

  // multiplier depends on how much the trainingcount
  private trainingMultiplier = (trainingCount: string): number => {
    let result: number = 0

    switch (trainingCount) {
      case 'Nem':
        result = 1.2
        break
      case 'Heti 1-2 napot, nem túl intenziven':
        result = 1.3
        break
      case 'Heti 3-5 napot, nem túl intenziven':
        result = 1.45
        break
      case 'Heti 3-5 napot, intenziven':
        result = 1.45
        break
      case 'Heti 5-7 napot, intenziven':
        result = 1.7
        break
    }

    return result
  }

  private loseWeightValue = (weight: string): number => {
    if (weight === 'Fogyni szeretnék 1-5 kilót') {
      return 400
    }

    if (
      weight === 'Fogyni szeretnék 5-10 kilót' ||
      weight === 'Fogyni szeretnék 10+ kilót'
    ) {
      return 500
    }

    return 0
  }

  private getBloodTestText = (lastBloodTest: string): string => {
    if (
      lastBloodTest === 'Egy évnél régebben' ||
      lastBloodTest === '2 évnél régebben' ||
      lastBloodTest === 'Soha'
    ) {
      return '<p>A vérvizsgálat is fontos: nagyon sok minden rossz irányba mehet (vércukor, vérnyomás, trigliceridek, hormonok, stb) és feltétlenül tudni kell róla, hogy időben vissza tudd forditani. Egy évben egyszer elég, és értékeld ki az orvosoddal, esetleg mutasd meg szakorvosoknak.</p>'
    }

    return ''
  }

  private getMacroText = (countMacros: string) => {
    if (
      countMacros === 'Nem' ||
      countMacros === 'Néha' ||
      countMacros === 'Nem tudom, miért lenne fontos' ||
      countMacros === 'Nem tudom, hogy kell'
    ) {
      return '<p>A makróidat pedig azért jó irni, mert látod, hogy megfelelő tápanyag arányokat vittél be, se nem sokat, se nem keveset (fehérje, szénhidrát, zsir). 3 napig mindenképp érdemes vezetni, meg fogsz lepődni. Ehhez kalóriaszámláló appokat használhatsz, vagy megkeresed a csomagoláson, Googleben, hogy mi miből mennyit tartalmaz.</p>'
    }

    return ''
  }

  private getMealDiaryText = (hasMealDiary: string): string => {
    if (
      hasMealDiary === 'Nem' ||
      hasMealDiary === 'Néha' ||
      hasMealDiary === 'Nem tudom, miért lenne fontos'
    ) {
      return '<p>Esetedben jó lenne étkezési naplót vezetni, amiben leirod, hogy mit ettél, és hogyan érzed magad aznap - igy megtudhatod, mik azok az ételek, amiket jobb, ha kihagysz, vagy mi az amitől kifejezetten jól érzed magad.</p>'
    }

    return ''
  }

  private getDietarySupplementText = (age: number): string => {
    if (age >= 40) {
      return '<p>Napi 10-20g glutamin, ami nemcsak fehérjepótlásra jó, de jótékony hatással van a gyomorra és a belekre is.</p><p>Nyers aloe vera (1 ek egy nap)<p>Korodból kifolyólag NAC vagy glutathion, ami erős antioxidáns, a gyomor kedvelt tápláléka</p><p>Cink, szelénium</p><p>B komplex vitamin</p>'
    }

    return '<p>Napi 10-20g glutamin, ami nemcsak fehérjepótlásra jó, de jótékony hatással van a gyomorra és a belekre is.</p><p>Nyers aloe vera (1 ek egy nap)</p><p>Cink, szelénium</p><p>B komplex vitamin</p>'
  }
  private getDietText = (sufferFromDisease: string[]): string => {
    let disease: string
    let sugarAddictText: string

    const forDelete = [
      'IR (inzulin ellenállás)',
      'Diabétesz',
      'Hisztamin allergia',
      'Magas vérnyomás',
      'Magas vércukor',
      'Pajzsmirigy alulműködés',
      'Pajzsmirigy túlműködés',
    ]

    let fiteredArr = sufferFromDisease.filter(item => !forDelete.includes(item))

    if (fiteredArr.some(item => item === 'Egyik sem')) {
      disease = ''
    } else {
      disease = fiteredArr.join(', ')
    }

    if (fiteredArr.some(item => item === 'Cukor/szénhidrát függés')) {
      sugarAddictText =
        '<p>A <a href="https://etkezztudatosan.hu/cukordetox/" target="_blank">Cukor detox étrend</a> segit megszabadulni a cukor és szénhidrát kivánástól.</p>'
    } else {
      sugarAddictText = ''
    }

    if (disease !== '') {
      return (
        '<p>A <a href="https://etkezztudatosan.hu/laposhasprogram/" target="_blank">lapos has programmal</a> gyógyítható a ' +
        disease +
        ', és emésztési zavarok.' +
        '</p>' +
        sugarAddictText
      )
    }

    return ''
  }

  private getIFdietText = (currentDiet: string[]): string => {
    if (
      currentDiet.some(
        item =>
          item === 'IF (időszakos böjt) - vegán' ||
          item === 'IF (időszakos böjt) - vegyes',
      )
    ) {
      return '<p><a href="https://etkezztudatosan.hu/if/" target="_blank">Időszakos böjtölés (Intermittent fasting) étrend</a>el beállíthatóak az éhséghormonok és segít elindulni a fogyásba.</p>'
    }

    return ''
  }

  private getLowCarbText = (currentDiet: string[]): string => {
    if (
      currentDiet.some(
        item =>
          item === 'Alacsony szénhidrát (vegán)' ||
          item === 'Alacsony szénhidrát (vegyes)',
      )
    ) {
      return '<p><a href="https://etkezztudatosan.hu/alacsonyszenhidrat/" target="_blank">Alacsony szénhidrát - zsirégető étrend</a> egy hosszabban fenttartható fogyást eredményez.</p>'
    }

    return ''
  }

  private getTeneDietText = (currentDiet: string[]): string => {
    if (
      currentDiet.some(
        item =>
          item === 'Teljes értékű növényi alapú TÉNÉ (amit megkivánok)' ||
          item === 'Teljes értékű, növényi alapú TÉNÉ (alacsony szénhidrát)' ||
          item === 'A napi tucat szerint étkezem',
      )
    ) {
      return '<p>A <a href="https://etkezztudatosan.hu/tene/" target="_blank">TÉNÉ étrend</a> a napi tucat elvei alapján van összeállitva, de ez nem a fogyást segiti elő, csak ha már a meglévő diétádhoz ragaszkodsz.</p>'
    }

    return ''
  }

  private getLinks = (): string => {
    return (
      '<p><a href="https://www.joinclubhouse.com/join/n%C3%B6v%C3%A9nyi-alap%C3%BA-%C3%A9tkez%C3%A9/j8VUbmpP" target="_blank">Csatlakozz a Növényi Alapú Étkezés klubbomhoz, naponta jelentkező élő beszélgetések, előadások, válaszok</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/cukordetox/" target="_blank">21 napos Cukor Detox étrend</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/felazizmotleazsirt/" target="_blank">Havi életmód program étrend tervvel és edzésekkel</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/laposhasprogram/" target="_blank">Lapos Has Program</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/erettnok/" target="_blank">Érett nők Étrend Programja</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/alacsonyszenhidrat/" target="_blank">Alacsony szénhidrát - zsirégető étrend</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/tene/" target="_blank">Teljes értékű növényi alapú (TÉNÉ) étrend</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/if/" target="_blank">Időszakos böjtölés (Intermittent fasting) étrend</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/3napos/" target="_blank">3 napos vegán tisztitás - ingyenes program</a></p>' +
      '<p><a href="https://etkezztudatosan.hu/podcast/" target="_blank">Hallgasd a podcastet</a></p>' +
      '<p>Kövesd az <a href="https://www.instagram.com/krisztinarudnay/" target="_blank">Instagram oldalam</a></p>' +
      '<p>Kövesd <a href="https://hu.pinterest.com/rudnaykrisztina/" target="_blank">Pinterestem</a></p>' +
      '<p>Nézd meg az <a href="https://etkezztudatosan.hu/" target="_blank">új honlapot</a></p>' +
      '<p>Csatlakozz <a href="https://www.facebook.com/groups/vegankajat" target="_blank">egy vidám csoporthoz ahol élő edzéseket és recepteket fedezhetsz fel!</a></p>' +
      '<p>Clubhouse: <a href="https://www.joinclubhouse.com/@rudnaykrisztina?fbclid=IwAR34YOcv-jP5jZ-x75GELLKKA1uo2vYK6yPGu5MdpTGyL7cv_3UuE-Ygtq4" target="_blank">@rudnaykrisztina</a> (minden nap előadások, kérdések/válaszok)</p>'
    )
  }

  private getLinkOfSourceText = (): string => {
    return (
      '<p><b>Források, amiket ajánlok:</b></p>' +
      '<p><a href="https://vegankajak.hu/podcast4/" target="_blank">4 tipp a fogyáshoz: mit, mikor, hogyan és hol</a></p>' +
      '<p><a href="https://vegankajak.hu/podcast6/" target="_blank">Miért kivánod a cukrot, és hogyan győzd le</a></p>' +
      '<p><a href="https://vegankajak.hu/podcast5/" target="_blank">Kerüld el a túlevést</a></p>' +
      '<p><a href="https://vegankajak.hu/podcast18/" target="_blank">Hogyan tervezz étrendet és étkezést</a></p>' +
      '<p><a href="https://www.facebook.com/krisztinarudnay/videos/4104011646276024" target="_blank">Tévhitek és igazságok tápanyagokról, étkezésről</a></p>'
    )
  }

  private getWomenDietText = (
    gender: string,
    isWantToLoseWeight: string,
    isPregnant: string,
    age: number,
  ): string => {
    let matureWomenText: string

    if (age >= 40 && gender === 'nő') {
      matureWomenText =
        '<p>Az <a href="https://etkezztudatosan.hu/erettnok/" target="_blank">Érett nők étrend terve</a> segit kiegyensúlyozni a hormonokat, és gondoskodik a megfelelő fehérje bevitelről.</p>'
    } else {
      matureWomenText = ''
    }

    if (
      (gender === 'nő' &&
        isPregnant === 'Nem' &&
        isWantToLoseWeight === 'Fogyni szeretnék 1-5 kilót') ||
      isWantToLoseWeight === 'Fogyni szeretnék 5-10 kilót' ||
      isWantToLoseWeight === 'Fogyni szeretnék 10+ kilót'
    ) {
      return (
        '<p>Ha van kedved, kipróbálhatod az <a href="https://etkezztudatosan.hu/if/" target="_blank">Intermittent fasting étrendet,</a> ezzel még gyorsabb lehet a fogyás. Ez egy időszakos böjtölés, időzitett étkezésekkel.</p><p><a href="https://etkezztudatosan.hu/alacsonyszenhidrat/" target="_blank">Az alacsony szénhidrát étrenddel</a> tovább segithető a fogyás. Ez nem egy keto étrend, hanem gondosan összeválogatott, alacsonyabb szénhidrát makro arányon tartott étrend.</p>' +
        matureWomenText
      )
    }

    if (
      (gender === 'nő' &&
        isPregnant === 'Igen' &&
        isWantToLoseWeight === 'Fogyni szeretnék 1-5 kilót') ||
      isWantToLoseWeight === 'Fogyni szeretnék 5-10 kilót' ||
      isWantToLoseWeight === 'Fogyni szeretnék 10+ kilót'
    ) {
      return (
        '<p><a href="https://etkezztudatosan.hu/alacsonyszenhidrat/" target="_blank">Az alacsony szénhidrát étrenddel</a> tovább segithető a fogyás. Ez nem egy keto étrend, hanem gondosan összeválogatott, alacsonyabb szénhidrát makro arányon tartott étrend.</p>' +
        matureWomenText
      )
    }

    return ''
  }

  private GetGenderNumber = (gender: string): number => {
    return gender === 'férfi' ? 5 : -161
  }

  public get bmrNumber() {
    const weight: number = this.data.weight
    const height: number = this.data.height
    const age: number = this.data.age
    const gender: number = this.GetGenderNumber(this.data.gender)
    const multiplier = this.trainingMultiplier(this.data.trainingCount)

    return this.BmrCalculation(weight, height, age, gender, multiplier)
  }

  public getTrainingText(): string {
    return '<b><p><a href="https://etkezztudatosan.hu/felazizmotleazsirt/">A tornáimról.</a></p></b><p>Hetente új kezdő és haladó gyakorlatsort kapnak a tagok.</p><p>Egyik sem magas intenzitású, és bár meg kell dolgozni az ismétlésekért, ezt nem olyan munkával kell tenni ami nagy stresszt okoz a szervezetnek (mint a magas intenzitású edzések). Kényelmesen, személyre szabva, saját tempódban és képességeidhez mérten tudod őket végezni.</p><p>Egyensúlyt teremtenek a testedben, segitenek az idegeknek és az ideg pályáknak éberen maradni, és kiegyensúlyozza a hormonokat.</p><p>Ha csatlakozol, akkor ezt egy részletes tervben felvázolhatom Neked egy következő állapotfelmérés után.</p>'
  }

  public Evaluate = (): string => {
    const age: number = this.data.age
    const weightToLose = this.loseWeightValue(this.data.healthTarget)

    const BMRNumber = this.bmrNumber

    let bmrText: string

    //Email message generation.
    if (
      this.data.trainingCount === 'Nem' ||
      this.data.trainingCount === 'Heti 1-2 napot, nem túl intenziven'
    ) {
      bmrText =
        '<p>A megadott információk alapján a napi kalória bevitelednek ' +
        BMRNumber +
        ' kcal lenne a legoptimálisabb mennyisége, ha a súlyodat megtartani szeretnéd és továbbra is ennyit edzel. Azonban ha felviszed napi 2-3 napra <a href="https://etkezztudatosan.hu/felazizmotleazsirt/" target="_blank">az edzések számát,</a> akkor ez felemelkedik ' +
        Math.floor((BMRNumber / 1.2) * 1.3) +
        ' kcal-ra.' +
        '</p><p>Ezt csökkenteném kezdetben napi 500 kalóriával. Kezdd 100 kalóriával naponta, és fokozatosan menj le 500-ig.</p>'
    } else {
      bmrText =
        '<p>A megadott információk alapján a napi kalória bevitelednek ' +
        BMRNumber +
        ' kcal lenne a legoptimálisabb mennyisége. Folytasd tovább a jelenlegi edzésed, ennek száma megfelelő.</p>'
    }

    if (BMRNumber - weightToLose < 1200) {
      bmrText +=
        '<p>Mivel az alapkalóriád ' +
        BMRNumber +
        ' kcal és ha ebből az értékből levonunk 500-at akkor a kalóriaszámod ' +
        (BMRNumber - weightToLose) +
        ' ' +
        ' lesz.' +
        ' Sajnos ebből a kalóriából nem lehet egészséges deficitet vonni, ezért esetedben csak az edzések jelenthetik a fogyást.</p>'
    }

    //Ami hardcodeolva van az mindenkinél szerepel mindig! Ezt lehet majd refakorálni, hogy szebb legyen.
    return (
      '<p>' +
      'Szia ' +
      this.data.secondName +
      '</p><br>' +
      '<p>Ezt az emailt azért küldöm, mert kitöltötted az Étkezz Tudatosan Állapotfelmérő lapját.</p>' +
      '<p>--------------</p>' +
      '<br>' +
      '<p><b>Alap kalóriák, deficit beállítása:</b></p>' +
      bmrText +
      this.getTrainingText() +
      '<p>--------------</p>' +
      '<p><b>Étrend</b></p>' +
      this.getDietText(this.data.sufferFromDisease) +
      this.getWomenDietText(
        this.data.gender,
        this.data.healthTarget,
        this.data.isPregnant,
        age,
      ) +
      this.getTeneDietText(this.data.currentDiet) +
      this.getLowCarbText(this.data.currentDiet) +
      this.getIFdietText(this.data.currentDiet) +
      this.getMealDiaryText(this.data.hasMealDiary) +
      this.getMacroText(this.data.countMacros) +
      this.getBloodTestText(this.data.lastBloodTest) +
      '<p>Sokat profitálhatnál a <a href="https://etkezztudatosan.hu/dolgozzvelem/" target="_blank">6 hetes, személyre szabott étkezési és életmód váltási programomból, </a>ahol csak veled foglalkozok 6 héten át, még részletesebb tervet készitve, még hamarabb elérve a várt eredményeket.</p>' +
      '<p>A feldolgozott ételeket, alkoholt, cukrot lehetőleg kerüld. A húst csökkentsd havi 2-3 alkalomra.</p>' +
      '<p>--------------</p>' +
      this.getLinkOfSourceText() +
      '<p><b>Kiegészítők (javaslatok):</b></p>' +
      this.getDietarySupplementText(this.data.age) +
      '<p>--------------</p>' +
      '<br><p>Ha bármi másban segíthetek, csak válaszolj erre az emailre.</p><p>Üdvözlettel:</p><br><p>Kriszti</p><br>' +
      '<p><b>Linkek:</b></p>' +
      this.getLinks()
    )
  }
}
