import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import {MoreVert as MoreIcon} from '@material-ui/icons'
import * as React from 'react'
import {useHistory} from 'react-router'
import {firebaseApp} from '../App'
import useConfirmation from '../hooks/useConfirmation'
import RouteLink from './RouteLink'

export interface ResponseListTableProps {
  // todo: replace any with actual type created for our form
  data: Array<any>
  refetch: () => Promise<void>
}

export const ResponseListTable: React.FC<ResponseListTableProps> = ({
  data,
  refetch,
}) => {
  const history = useHistory()
  const [, setConfirmation] = useConfirmation()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handlePopoverClose() {
    setAnchorEl(null)
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // todo: replace any with actual type created for our form data
  function handleOpenResponse(response: any) {
    history.push(`/response/${response.id}`)
  }

  // todo: replace any with actual type created for our form data
  function removeResponse(response: any) {
    return async () => {
      await firebaseApp.database().ref(`results/${response.id}`).remove()
      await refetch()
    }
  }

  // todo: replace any with actual type created for our form data
  function handleRemoveResponse(response: any) {
    handlePopoverClose()

    setConfirmation({
      title: 'Törlés megerősítése',
      message: `Valóban ki szeretnéd törölni ${response.firstName} ${response.secondName} eredményét?`,
      onOkClick: removeResponse(response),
    })
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Név</strong>
              </TableCell>
              <TableCell>
                <strong>Email cím</strong>
              </TableCell>
              <TableCell>
                <strong>Életkor</strong>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(response => (
                <TableRow key={response.id}>
                  <TableCell>
                    <RouteLink to={`/response/${response.id}`}>
                      {response.firstName} {response.secondName}
                    </RouteLink>
                  </TableCell>
                  <TableCell>{response.email}</TableCell>
                  <TableCell>{response.age}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      id={response.id}
                      aria-label="Visszajelzés műveletek"
                      aria-controls={`menu-${response.id}`}
                      aria-haspopup="true"
                      onClick={handlePopoverOpen}
                    >
                      <MoreIcon />
                    </IconButton>

                    <Menu
                      id={`menu-${response.id}`}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={!!anchorEl && anchorEl.id === response.id}
                      onClose={handlePopoverClose}
                    >
                      <MenuItem onClick={() => handleOpenResponse(response)}>
                        Megnyitás
                      </MenuItem>

                      <MenuItem onClick={() => handleRemoveResponse(response)}>
                        Törlés
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[10, 20, 30]}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}
