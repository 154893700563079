import * as React from 'react'
import {NotificationContext} from '../contexts/NotificationContext'

export function useNotification() {
  const context = React.useContext(NotificationContext)

  if (!context) {
    throw new Error(
      `'useNotification' should be used within a NotificationProvider.`,
    )
  }

  return context
}

export default useNotification
