import React from 'react'
import Questionnaire from '../components/Questionnaire'
import {useEmailService} from '../hooks/useEmailService'
import {useNotification} from '../hooks/useNotification'
import {Evaluation} from '../lib/Evaluation'

export const IndexPage: React.FC = () => {
  const {sendEmail} = useEmailService()
  const [, setNotification] = useNotification()

  // todo: replace any with actual types of our form
  async function handleSubmit(values: any) {
    const evaluation = new Evaluation(values)

    try {
      if (
        values.isSomethingWorrying.toLowerCase() === 'igen' ||
        values.isDisabledFromDiet.toLowerCase() === 'igen' ||
        // TODO: Replace values.comment with the name of the free-text input
        (values.comment && values.comment.length)
      ) {
        await sendEmail({
          to: 'hello@etkezztudatosan.hu',
          subject: `Vedd fel a kapcsolatot a ${values.firstName} ${values.secondName} nevű kitöltővel`,
          message: `Kérlek ellenőrizd ${values.firstName} ${values.secondName} válaszait, mert egy vagy több olyan választ adott, ami alapján további beszélgetést kell folytatnotok.`,
          authenticity: values.authenticity,
        })

        //todo:
        await sendEmail({
          to: values.email,
          subject:
            'Köszönöm a kitöltést! Hamarosan felveszem veled a kapcsolatot.',
          message: evaluation.Evaluate(),
          authenticity: values.authenticity,
        })
        setNotification({
          text:
            'Köszönöm a kitöltést! Hamarosan felveszem veled a kapcsolatot.',
          type: 'success',
        })
      } else {
        await sendEmail({
          to: values.email,
          subject: 'Köszönöm a kitöltést! Tekintsd meg a kiértékelést',
          message: evaluation.Evaluate(),
          authenticity: values.authenticity,
        })

        setNotification({
          text:
            'Köszönöm a kitöltést! A kiértékelést elküldtem neked emailben.',
          type: 'success',
        })
      }
    } catch {
      setNotification({
        text: 'Hiba történt az email elküldése során!',
        type: 'error',
      })
    }
  }

  return (
    <div>
      <Questionnaire onSubmit={handleSubmit} />
    </div>
  )
}

export default IndexPage
