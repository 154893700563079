import * as React from 'react'
import {firebaseApp} from '../App'
import {errorMessages} from '../messages/errors'
import useNotification from './useNotification'

export function useSignOut() {
  const [, setNotification] = useNotification()

  async function signOut() {
    setNotification(undefined)

    try {
      await firebaseApp.auth().signOut()
    } catch (error) {
      setNotification({
        text: error.message || errorMessages.unknownError,
        type: 'error',
      })
    }
  }

  return React.useCallback(signOut, [setNotification])
}

export default useSignOut
