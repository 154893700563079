import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core'
import {green} from '@material-ui/core/colors'

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: '"Helvetica", Arial, sans-serif',
    body1: {
      fontSize: '17px',
      fontWeight: 'normal',
    },
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600,
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
        fontWeight: 600,
      },
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
      '@media (min-width:600px)': {
        fontSize: '1.2rem',
        fontWeight: 400,
      },
    },
  },
})
