import {Box, Button, Container, Grid, Paper} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import clsx from 'clsx'
import {Form, Formik, FormikHelpers} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {firebaseApp} from '../App'
import {useNotification} from '../hooks/useNotification'
import {Evaluation} from '../lib/Evaluation'
import {availableInputs, inputGroups} from '../lib/formVariables'
import CheckboxGroup from './CheckboxGroup'
import Field from './Field'
import RadioGroup from './RadioGroup'

export interface QuestionnaireProps {
  initialValues?: any
  readOnly?: boolean
  // todo: replace any with actual types
  onSubmit?: (values: any) => Promise<void>
}

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(6),
    color: '#fff',
    fontWeight: 600,
  },
  root: {
    '& .MuiFormControl-root': {
      width: '50%',
      margin: theme.spacing(1),
    },
  },
  quoteContainer: {
    opacity: 0.85,
    padding: theme.spacing(2, 0, 2, 6),
    borderLeft: `3px solid ${theme.palette.grey[500]}`,
    marginTop: theme.spacing(4),
  },
  heading: {
    marginTop: theme.spacing(1),
  },
  userInf: {
    marginTop: theme.spacing(3),
    borderRadius: '15px',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  questions: {
    marginTop: theme.spacing(3),
    borderRadius: '15px',
    padding: theme.spacing(5),
    textAlign: 'left',
  },
  grid: {
    flexDirection: 'column',
    '@media (min-width:600px)': {
      flexDirection: 'row',
    },
  },
}))

// Validation rule definitions
const requiredTextFieldValidation = Yup.string()
  .nullable()
  .required('Kötelező mező')
const requiredNumberFieldValidation = Yup.number()
  .nullable()
  .required('Kötelező mező')
const requiredCheckboxFieldValidation = Yup.array()
  .required('Kötelező mező')
  .min(1, 'Válassz ki legalább egy elemet a listából')

export const Questionnaire: React.FC<QuestionnaireProps> = ({
  initialValues,
  readOnly,
  onSubmit,
}) => {
  const classes = useStyles()
  const database = firebaseApp.database()
  const [, setNotification] = useNotification()

  // todo: replace any with actual types
  async function handleSubmit(
    data: any,
    {resetForm, setSubmitting}: FormikHelpers<any>,
  ) {
    if (readOnly) {
      return
    }

    try {
      await firebaseApp.auth().signOut()
      const credentials = await firebaseApp.auth().signInAnonymously()

      if (credentials.user) {
        await database.ref(`results/${credentials.user.uid}`).set(data)
      }

      // note: processing data and sending email to the relevant email addresses
      if (onSubmit) {
        await onSubmit(data)
      }

      resetForm()
      setSubmitting(false)
      window.open('https://etkezztudatosan.hu/', '_self')
    } catch (error) {
      setNotification({text: error.message, type: 'error'})
    }
  }

  return (
    <div>
      <Formik
        initialValues={
          initialValues ?? {
            firstName: '',
            secondName: '',
            age: '',
            email: '',
            weight: '',
            height: '',
            ...availableInputs.reduce(
              (accumulator, field) => ({
                ...accumulator,
                [field.name]: field.value,
              }),
              {},
            ),
          }
        }
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          firstName: requiredTextFieldValidation
            .min(2, 'Minimum 2 karakter hosszú legyen a szöveg')
            .max(12, 'Maximum 12 karakter hosszú legyen a szöveg'),
          /*.matches(
              /^[A-zÀ-ű]+$/,
              'Ez a mező kizárólag karaktereket tartalmazhat',
            )*/ secondName: requiredTextFieldValidation
            .min(2, 'Minimum 2 karakter hosszú legyen a szöveg')
            .max(12, 'Maximum 12 karakter hosszú legyen a szöveg'),
          /*.matches(
              /^[A-zÀ-ű]+$/,
              'Ez a mező kizárólag karaktereket tartalmazhat',
            )*/ age: requiredNumberFieldValidation
            .integer('Számnak kell lennie')
            .typeError('Számnak kell lennie')
            .min(2, 'Pontosan 2 számjegynek kell lennie')
            .max(2, 'Pontosan 2 számjegynek kell lennie')
            .lessThan(90, 'Kisebbnek kell lennie, mint 90')
            .moreThan(16, 'Nagyobbnak kell lennie, mint 16'),
          email: requiredTextFieldValidation.email('Érvénytelen email cím'),
          weight: requiredNumberFieldValidation
            .integer('Számnak kell lennie')
            .min(2, 'Minimum 2 számjegynek kell lennie')
            .max(3, 'Maximum 3 számjegynek kell lennie')
            .lessThan(160, 'Kisebbnek kell lennie, mint 160')
            .moreThan(40, 'Nagyobbnak kell lennie, mint 40'),
          height: requiredNumberFieldValidation
            .integer('Számnak kell lennie')
            .min(3, 'Pontosan 3 számjegynek kell lennie')
            .max(3, 'Pontosan 3 számjegynek kell lennie')
            .lessThan(220, 'Kisebbnek kell lennie, mint 220')
            .moreThan(139, 'Nagyobbnak kell lennie, mint 139'),
          ...availableInputs.reduce((accumulator, field) => {
            if (field.type === 'checkbox') {
              return {
                ...accumulator,
                [field.name]: requiredCheckboxFieldValidation,
              }
            }

            return {...accumulator, [field.name]: requiredTextFieldValidation}
          }, {}),
        })}
      >
        {({values, isSubmitting}) => (
          <Form>
            <Container maxWidth="lg" className={classes.root}>
              <div className={classes.heading}>
                <Typography variant="h3">Állapotfelmérő kérdőív</Typography>
                <Typography variant="h4">
                  Mielőtt az általam javasoltakat elkezded, kérd ki a
                  kezelőorvosod véleményét.
                </Typography>
              </div>

              {/*Name input form*/}
              <Paper className={classes.userInf}>
                <Grid container className={classes.grid}>
                  <Grid item md={5}>
                    <Typography>Vezetéknév</Typography>
                    <Field
                      id="outlined-required"
                      label="Vezetéknév"
                      variant="outlined"
                      placeholder="Vezetéknév"
                      name="firstName"
                      type="input"
                      required
                      shrink
                      disabled={readOnly}
                    />
                    <Typography>Keresztnév</Typography>
                    <Field
                      id="outlined-required"
                      label="Keresztnév"
                      variant="outlined"
                      placeholder="Keresztnév"
                      name="secondName"
                      type="input"
                      required
                      shrink
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item md={5}>
                    {/*Age input form*/}
                    <Typography>Életkor</Typography>
                    <Field
                      id="outlined-number"
                      label="Életkor"
                      variant="outlined"
                      placeholder="Életkor"
                      type="number"
                      name="age"
                      required
                      shrink
                      disabled={readOnly}
                    />

                    {/*Email input form*/}
                    <Typography>Email</Typography>
                    <Field
                      id="outlined-required"
                      label="Email"
                      variant="outlined"
                      placeholder="Email"
                      name="email"
                      type="email"
                      required
                      shrink
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item md={5}>
                    {/* Height */}
                    <Typography>Magasság</Typography>
                    <Field
                      id="outlined-number"
                      label="Magasság"
                      variant="outlined"
                      placeholder="Magasság"
                      type="number"
                      name="height"
                      required
                      shrink
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item md={5}>
                    {/* Weight */}
                    <Typography>Súly</Typography>
                    <Field
                      id="outlined-number"
                      label="Súly"
                      variant="outlined"
                      placeholder="Súly"
                      type="number"
                      name="weight"
                      required
                      shrink
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Grid>
                {/* Inputs from our form configuration file */}
                {inputGroups.map(group => (
                  <div key={group.name}>
                    <Paper className={classes.questions}>
                      <Grid item>
                        {group.type === 'radio' && (
                          <RadioGroup
                            name={group.name}
                            radioProps={group.inputFields}
                            questionLabel={group.question}
                            readOnly={readOnly}
                          />
                        )}

                        {group.type === 'checkbox' && (
                          <CheckboxGroup
                            name={group.name}
                            checkboxProps={group.inputFields}
                            questionLabel={group.question}
                            readOnly={readOnly}
                          />
                        )}
                      </Grid>
                    </Paper>
                  </div>
                ))}
              </Grid>
            </Container>

            {!readOnly && (
              <div>
                {/*Submit button*/}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<SendIcon />}
                >
                  Beküldés
                </Button>
              </div>
            )}

            {readOnly && (
              <Box marginTop={6}>
                <Container maxWidth="lg" className={clsx(classes.root)}>
                  <Typography>
                    <strong>Kiküldött email szövege:</strong>
                  </Typography>

                  <Box className={classes.quoteContainer}>
                    <code
                      dangerouslySetInnerHTML={{
                        __html: new Evaluation(values).Evaluate(),
                      }}
                    />
                  </Box>
                </Container>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Questionnaire
