import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {Replay as ReplayIcon, Search as SearchIcon} from '@material-ui/icons'
import {Formik, FormikHelpers} from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import {firebaseApp} from '../App'
import {LoginForm} from '../components/LoginForm'
import {ResponseListTable} from '../components/ResponseListTable'
import useAuthentication from '../hooks/useAuthentication'
import useNotification from '../hooks/useNotification'
import useSignOut from '../hooks/useSignOut'
import {AuthenticatedLayout} from '../layout'
import {errorMessages} from '../messages/errors'
import {removeDiacritics} from '../utils'

interface LoginFormValues {
  email: string
  password: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.invalidEmail)
    .nullable()
    .required(errorMessages.fieldRequired),
  password: Yup.string().nullable().required(errorMessages.fieldRequired),
})

const initialValues: LoginFormValues = {
  email: '',
  password: '',
}

const useStyles = makeStyles(theme => ({
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
}))

export const AdminPage: React.FC = () => {
  const classes = useStyles()
  const {user, authState} = useAuthentication()
  const [, setNotification] = useNotification()
  const signOut = useSignOut()
  // todo: replace any with actual type created for our form
  const [data, setData] = React.useState<Array<any>>()
  // todo: replace any with actual type created for our form
  const [filteredData, setFilteredData] = React.useState<Array<any>>()

  function handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget && event.currentTarget.value && data) {
      const {value} = event.currentTarget
      const searchTerm = removeDiacritics(value.toLowerCase())

      setFilteredData(
        data.filter(
          ({firstName, secondName}) =>
            removeDiacritics(firstName).toLowerCase().includes(searchTerm) ||
            removeDiacritics(secondName).toLowerCase().includes(searchTerm),
        ),
      )
    } else {
      setFilteredData(undefined)
    }
  }

  async function handleSignIn(
    values: LoginFormValues,
    {setSubmitting}: FormikHelpers<LoginFormValues>,
  ) {
    setNotification(undefined)
    setSubmitting(true)

    try {
      await firebaseApp.auth().setPersistence('local')

      await firebaseApp
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
    } catch (error) {
      setNotification({
        text: error.message || errorMessages.unknownError,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    async function checkIfUserIsAnonymous() {
      // Note: We don't want anonymous users to do anything on the admin page
      if (user && user.isAnonymous) {
        await signOut()
      }
    }

    checkIfUserIsAnonymous()
  }, [user, signOut])

  const fetchResults = React.useCallback(
    async function fetchResults() {
      if (user && !user.isAnonymous) {
        const data = await firebaseApp.database().ref('results').once('value')
        const value = data.val()

        const mappedResponseData = value
          ? Object.keys(value).map(key => ({...value[key], id: key}))
          : []

        setData(mappedResponseData)
      }
    },
    [setData, user],
  )

  React.useEffect(() => {
    fetchResults()
  }, [fetchResults])

  if (authState === 'pending') {
    return <CircularProgress />
  }

  if (authState === 'rejected') {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSignIn}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({isSubmitting}) => <LoginForm isSubmitting={isSubmitting} />}
      </Formik>
    )
  }

  if (authState === 'resolved') {
    return (
      <AuthenticatedLayout onLogout={signOut}>
        {data ? (
          <Box>
            <Box className={classes.tableToolbar}>
              {data.length > 0 && (
                <Input
                  startAdornment={<SearchIcon />}
                  placeholder="Keresés"
                  onChange={handleFilterChange}
                />
              )}

              <IconButton aria-label="Frissítés" onClick={fetchResults}>
                <ReplayIcon />
              </IconButton>
            </Box>

            {data.length > 0 ? (
              <ResponseListTable
                data={filteredData ?? data}
                refetch={fetchResults}
              />
            ) : (
              <Typography>
                Jelenleg még senki nem töltötte ki a formot.
              </Typography>
            )}
          </Box>
        ) : (
          <CircularProgress />
        )}
      </AuthenticatedLayout>
    )
  }

  return null
}

export default AdminPage
